import React from 'react'
import Layout from '../components/LayoutView'
import { graphql, Link } from 'gatsby'

const Cookie = () => {
 
  return (
    <Layout title='COOKIE POLICY'>
      
      <div>
        <div className='user-list-area pd-top-90 pd-bottom-70'>
          <div className='container'>
            <div className='section-title text-center'>
              <h2 className='title'>COOKIE POLICY</h2>
            </div>
            
            
<h3>1. WHAT ARE COOKIES?</h3>
<p>Cookies are small text files that are placed on your device when you visit a website.
    They are widely used to make websites work more efficiently and to provide information to the owners of the site.
</p>

<h3>2. HOW WE USE COOKIES</h3>
<p>We use cookies on our website to improve your experience and to provide us with information about how our website is being used. 
    Specifically, we use cookies to:
    <ul>
        <li>Remember your preferences and settings.</li>
        <li>Provide personalized content and recommendations.</li>
        <li>Measure and analyze the performance of our website.</li>
        <li>Detect and prevent fraud and other security incidents.</li>
    </ul>
</p>

<h3> 3.TYPES OF COOKIES WE USE </h3>
<p>We use both first-party and third-party cookies on our website. First-party cookies are set by our website, while third-party cookies are set by third-party services that we use on our website, such as Google Analytics and Facebook Pixel.
</p>

<p>The types of cookies we use on our website include:</p>
<p>
    <ul>
        <li>Essential cookies: These cookies are necessary for the website to function properly and cannot be turned off in our systems.</li>
        <li>Analytics cookies: These cookies allow us to collect information about how visitors use our website, such as which pages are visited most often and how long visitors spend on each page.</li>
        <li>Personalization cookies: These cookies are used to remember your preferences and to personalize your experience on our website.</li>
        <li>Advertising cookies: These cookies are used to deliver relevant ads to you based on your interests and browsing history.</li>
    </ul>
</p>

<h3>4. HOW TO CONTROL COOKIES</h3>
<p>You can control cookies through your browser settings. Most browsers allow you to block or delete cookies, or to only accept certain types of cookies.
Please note that if you choose to block or delete cookies, some features of our website may not function properly.

</p>

<h3>5. CHANGES TO THIS POLICY</h3>
<p>We may modify this Cookie Policy at any time. If we make material changes to this Policy, we will notify you by posting a notice on our website.
</p>

<h3>6. CONTACT US</h3>
<p> If you have any questions or concerns about this Cookie Policy, please contact us at emordiuche@ownahome.ng. </p>



          </div>
        </div>
      </div>
    </Layout>
  )
}


export default Cookie
